<template>
  <dialogComp title="修改后台成本" :isShow="isShow" @close="close" @sure="sure">
    <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
      <el-form-item label="工单编号:">
        {{info.code}}
      </el-form-item>
      <el-form-item label="原后台成本:">
        {{info.managerPrice}}
      </el-form-item>
      <inputFormComp
        label="修改后成本:"
        prop="price"
        inputType="number"
        :hval="formData.price"
        @blur="priceBlur"
        placeholder="请输入修改后成本" />
        <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import { checkPrice } from '@/utils/common'
import { updateBackCost } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        price: '',
        remark: ''
      },
      rules: {
        price: [
          { required: true, message: '请输入单价', trigger: 'blur' },
          { validator: checkPrice, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.formData.remark = this.info.remark
      }
    }
  },
  methods: {
    priceBlur (val) {
      this.formData.price = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          updateBackCost({ id: this.info.id, ...this.formData }).then(_ => {
            this.$message.success('修改成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
