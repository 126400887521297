<template>
  <div style="margin-top:20px">
    <div class="search_flex">
      <div class="search_left">
        <selectComp
          :hval="status"
          :data="auditStatusList"
          @change="statusChange"
          placeholder="审核状态"
        />
         <selectComp
          :hval="orderStatus"
          :data="orderStatusList"
          @change="orderStatusChange"
          placeholder="工单状态"
        />
        <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
        <merchant :hval="storeName" @storeNameChange="storeNameChange" />
        <goodCategory :hval="categoryId" @change="categoryIdChange" />
        <selectComp
          prop="type"
          placeholder="处理方式"
          :data="typeList"
          :hval="type"
          @change="typeChange"
        />
        <dateComp
          :hval="date"
          startPlaceholder="建单开始日期"
          endPlaceholder="建单结束日期"
          @change="dateChange"
        />
        <btnGroup @search="search" @reset="reset" />
        <template v-if="tabType === 'entrust'">
          <btnComp
            v-permission="'批量审核'"
            :disabled="allotDisabled"
            btnType="plain"
            @click="batchHandle(2, 'batchHandle')"
            >批量审核</btnComp
          >
          <btnComp
            v-permission="'批量结算'"
            :disabled="allotDisabled"
            btnType="plain"
            @click="batchHandle(3, 'batchHandle')"
            >批量结算</btnComp
          >
        </template>
        <btnComp
          v-if="tabType === 'entrust'"
          v-permission="'改商家自管'"
          :disabled="allotDisabled"
          btnType="plain"
          @click="changeType(2, 'changeType')"
          >改商家自管</btnComp
        >
        <btnComp
          v-else
          v-permission="'改平台托管'"
          :disabled="allotDisabled"
          btnType="plain"
          @click="changeType(1, 'changeType')"
        >
          改平台托管</btnComp
        >
      </div>
    </div>
    <tableComp
      v-loading="tableLoading"
      :data="tableData"
      :col-configs="theadName"
      :total="total"
      :currentPage="curPage"
      :pageSize="pageSize"
      @select="select"
      isShowCheckBox
      @selectAll="selectAll"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column slot="code" label="工单编号">
        <template v-slot="scope">
          <div
            style="color:#385BFF;cursor:pointer"
            @click="codeClick(scope.row)"
          >
            {{ scope.row.code }}
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="visitPrice" label="上门成本">
        <template v-slot="scope">
          <span :style="{ color: scope.row.priceAlignment ? '' : 'red' }">{{
            scope.row.visitPrice || '-'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        slot="operate"
        label="操作"
        fixed="right"
      >
        <template v-slot="scope">
          <btnComp
            v-permission="'改后台成本'"
            v-if="scope.row.status !== 3"
            style="margin-left:10px"
            btnType="text"
            @click="updateCost(scope.row)"
            >改后台成本</btnComp
          >
          <btnComp
            v-permission="'同步上门成本'"
            v-if="!scope.row.priceAlignment"
            btnType="text"
            @click="syncSceneCost(scope.row)"
            >同步上门成本</btnComp
          >
          <!-- <btnComp btnType="text" @click="detail(scope.row)">反审核</btnComp>
          <btnComp btnType="text" @click="detail(scope.row)">反结算</btnComp> -->
        </template>
      </el-table-column>
    </tableComp>
    <!-- 详情弹窗 -->
    <drawerDetail @close="close" />
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="hintSure">
      <p>{{ `确定批量${title}吗？` }}</p>
    </dialogComp>
    <!-- 修改后台成本 -->
    <updateCost :isShow="costShow" @close="close" :info="info" />
  </div>
</template>

<script>
import updateCost from './updateCost'
import drawerDetail from '@/views/work-order/all-order/detail'
import merchant from '@/views/components/merchant'
import goodCategory from '@/views/components/goodCategory'
import {
  storeOrderAuditList,
  storeOrderAuditUpdateStatus,
  storeOrderAuditChangeType,
  syncSceneCost
} from '@/api'
import { formatDate } from '@/utils/common'
import { mapMutations } from 'vuex'
export default {
  components: {
    drawerDetail,
    updateCost,
    goodCategory,
    merchant
  },
  props: {
    // tabs类型
    tabType: {
      type: String,
      default: 'entrust'
    }
  },
  data () {
    return {
      tableLoading: false,
      costShow: false,
      hintShow: false,
      hintType: '', // 区分是批量审核结算还是修改商家自营、商家托管
      selectIds: [],
      info: {},
      curPage: 1,
      pageSize: 10,
      total: 0,
      title: '',
      status: '',
      orderStatus: '',
      code: '',
      categoryId: '',
      storeId: '',
      storeName: '',
      type: '',
      date: [],
      auditStatusList: [
        { label: '待审核', value: 1 },
        { label: '待结算', value: 2 },
        { label: '已结算', value: 3 }
      ],
      typeList: [
        { label: '远程处理', value: 1 },
        { label: '上门处理', value: 2 }
      ],
      orderStatusList: [
        { label: '待领取', value: 1 },
        { label: '处理中', value: 2 },
        { label: '待回访', value: 3 },
        { label: '已完结', value: 4 }
      ],
      tableData: [],
      theadName: [
        { prop: 'payStatusName', label: '支付状态' },
        { prop: 'statusName', label: '审核状态' },
        { prop: 'orderStatusName', label: '工单状态' },
        { slot: 'code' },
        { prop: 'storeName', label: '商家' },
        { prop: 'categoryName', label: '设备分类' },
        { prop: 'amount', label: '报修数量(台)' },
        { prop: 'typeName', label: '处理方式' },
        { prop: 'provinceCityRegion', label: '上门区县' },
        // { prop: 'visitPrice', label: '上门成本' },
        { slot: 'visitPrice' },
        { prop: 'managerPrice', label: '后台成本' },
        { prop: 'remark', label: '备注' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        storeSettlementType: this.tabType === 'entrust' ? 1 : 2,
        status: this.status,
        orderStatus: this.orderStatus,
        code: this.code,
        categoryId: this.categoryId,
        storeId: this.storeId,
        type: this.type,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.tableLoading = true
      storeOrderAuditList(params).then(res => {
        this.tableLoading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    // 点击工单号弹窗
    codeClick (info) {
      const { orderId, categoryId, orderCode } = info
      this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id: orderId, categoryId, orderCode })
    },
    // 处理时间
    handleDate (date) {
      return date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : '-'
    },
    codeBlur (val) {
      this.code = val
    },
    statusChange (val) {
      this.status = val
    },
    orderStatusChange (val) {
      this.orderStatus = val
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    categoryIdChange (val) {
      this.categoryId = val ? val.resultId : ''
    },
    typeChange (val) {
      this.type = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    batchHandle (batchStatus, type) {
      this.title = batchStatus === 2 ? '审核' : '结算'
      this.hintType = type
      this.batchStatus = batchStatus
      this.hintShow = true
    },
    // 修改后台成本
    updateCost (info) {
      this.info = info
      this.costShow = true
    },
    // 同步上门成本
    syncSceneCost ({ id }) {
      syncSceneCost({ id }).then(_ => {
        this.$message.success('同步成功')
        this.getList()
      })
    },
    // 平台托管、商家自管修改
    changeType (storeSettlementType, type) {
      this.title = this.tabType === 'entrust' ? '改商家自管' : '改平台托管'
      this.hintType = type
      this.storeSettlementType = storeSettlementType
      this.hintShow = true
    },
    hintSure () {
      // 批量审核结算
      if (this.hintType === 'batchHandle') {
        const idList = this.selectData.map(item => ({
          id: item.id,
          code: item.code
        }))
        const params = {
          idList,
          status: this.batchStatus
        }
        storeOrderAuditUpdateStatus(params).then(_ => {
          this.$message.success(`${this.title}成功`)
          this.close()
        })
      } else {
        // 修改商家自管、商家托管
        const params = {
          orderIdList: this.selectOrderIds,
          storeSettlementType: this.storeSettlementType
        }
        storeOrderAuditChangeType(params).then(_ => {
          this.$message.success(`${this.title}成功`)
          this.close()
        })
      }
    },
    close () {
      this.selectIds = []
      this.hintShow = false
      this.costShow = false
      this.SET_DRAWER_SHOW(false)
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.status = ''
      this.orderStatus = ''
      this.code = ''
      this.storeId = ''
      this.storeName = ''
      this.categoryId = ''
      this.type = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
      this.selectOrderIds = val.map(item => item.orderId)
      this.selectData = val
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
      this.selectOrderIds = val.map(item => item.orderId)
      this.selectData = val
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
